@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,700);

$mainNavHeight: 30px;
$titleHeight: 74px;
$subNavHeight: 24px;
$footerHeight: 30px;
$primary: #004F9D;
$screenHeight: calc(100vh - 188px); // 128px header, 30px footer, 30px padding
$matchHeight: calc(100vh - 188px - 60px); // 128px header, 30px footer, 30px padding

@import 'auth';
@import 'frontend';
@import 'client';
@import 'billing';
@import 'screen';
@import 'panel';
@import 'table';
@import 'match';

#root {
    width: 100%;
}