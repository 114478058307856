.billing_wrap {
    padding-top: 50px;
}
.billing {
    max-width: 420px;
    margin: auto;
    background: #fff;
    border: 1px solid #666;
    border-radius: 8px;
    h1 {
        font-size: 24px; 
    }
    h2 {
        font-size: 20px;
    }
}