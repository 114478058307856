a {
  cursor: pointer;
}
.client {
  width: 100%;
  height: 100vh;
  background: url(/assets/images/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  padding: 0;
  font-family:'Open Sans', sans-serif;
	font-size:13px;
  color:#393a3f;
  header {
    nav.main {
      position: relative;
      height: $mainNavHeight;
      background: url(/assets/images/mainbar.png) repeat-x;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;
        li {
          display: inline-block;
          border-right: 1px solid #C1C1C1;
          border-left: 1px solid #fff;
          a {
            cursor: pointer;
            display: block;
            color: #4A4948;
            text-decoration: none;
            text-transform: uppercase;
            line-height: $mainNavHeight;
            font-weight: 700;
            font-size: 12px;
            padding: 0 15px;
          }
        }
        li:first-of-type {
          border-left: 0;
        }
        li:last-of-type {
          border-right: 0;
        }
        li.active {
          background: #004F9D;
          border-right: 1px solid #004181;
          a {
            color: #fff;
          }
        }
        li.match-tab {
          background-image: linear-gradient(#83D475, #57C84D);
          border-right: 1px solid #228B22;
          a {
            color: #fff;
          }
        }
      }
      ul.header-controls {
        position: absolute;
        top: 0;
        right: 10px;
        float: right;
        li {
          border: 0;
          padding-top: 3px;
          padding-left: 5px;
        }
        button {
          display: block;
          border: 0;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          outline: 0;
          text-align: center;
        }
        button.user {
          background: #009ee3;
          color: #fff;
        }
        button.chat {
          background: #8ab31d;
          color: #fff;
        }
        button.settings {
          background: #434852;
          color: #fff;
        }
        button.logout {
          background: #434852;
          color: #fff;
        }
      }
    }
    .title {
      height: $titleHeight;
      background: url(/assets/images/titlebg.png) repeat-x;
      padding: 10px 15px;
      h1 {
        margin: 0;
        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 26px;
        color: #fff;
        padding: 0;
        line-height: 34px;
      }
      h2 {
        margin: 0;
        padding: 0;
        text-align: left;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 20px;
      }
    }
    nav.sub {
      height: $subNavHeight;
      background: #EBEBEB;
      border-top: 1px solid #DCDCDC;
      border-bottom: 1px solid #fff;
      ul {
        height: 22px;
        list-style: none;
        margin: 0 0 0 -10px;
        padding: 0;
        li {
          background: url(/assets/img/subnav.png) repeat-x;
          display: table-cell;
          height: 22px;
          line-height: 22px;
          border-right: 1px solid #fff;
          -moz-transform: skew(-30deg);
          -o-transform: skew(-30deg);
          -webkit-transform: skew(-30deg);
          -ms-transform: skew(-30deg);
          transform: skew(-30deg);
          a {
            cursor: pointer;
            display: block;
            color: #777;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 12px;
            display: block;
            -moz-transform: skew(30deg);
            -o-transform: skew(30deg);
            -webkit-transform: skew(30deg);
            -ms-transform: skew(30deg);
            transform: skew(30deg);
            padding: 0 15px;
          }
        }
        li:first-child {
            padding-left: 10px;
        }
        li.active {
            background: $primary;
            a {
                color: #fff;
            }
        }
    }
    }
  }
  footer {
    height: $footerHeight;
    line-height: $footerHeight;
    background: red;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 0 15px;
    .version {
      float: right;
    }
  }
  main {
    position: relative;
    height: calc(100vh - 150px);
    background: transparent;
    padding: 10px;
  }
}
