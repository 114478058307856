.panel {
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.5);
    .panel-header {
        background: $primary;
        color: #fff;
        padding: 0 15px;
        border-bottom: 1px solid #fff;
        h2 {
            text-align: left;
            font-size: 13px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            padding: 0;
            margin: 0;
            line-height: 30px;
        }
    }
    .panel-body {
        padding: 15px;
    }
}
.panel.full {
    height: $screenHeight;
}