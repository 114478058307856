.frontend {
    /*
    * Globals
    */

    /* Links */
    a,
    a:focus,
    a:hover {
    color: #fff;
    }

    /* Custom default button */
    .btn-secondary,
    .btn-secondary:hover,
    .btn-secondary:focus {
    color: #333;
    text-shadow: none; /* Prevent inheritance from `body` */
    background-color: #fff;
    border: .05rem solid #fff;
    }


    /*
    * Base structure
    */

    html,
    body {
    height: 100%;
    background-color: #333;
    }

    body {
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
    }

    .cover-container {
    max-width: 42em;
    }


    /*
    * Header
    */
    .masthead {
    margin-bottom: 2rem;
    }

    .masthead-brand {
    margin-bottom: 0;
    }

    .nav-masthead .nav-link {
    padding: .25rem 0;
    font-weight: 700;
    color: rgba(255, 255, 255, .5);
    background-color: transparent;
    border-bottom: .25rem solid transparent;
    }

    .nav-masthead .nav-link:hover,
    .nav-masthead .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, .25);
    }

    .nav-masthead .nav-link + .nav-link {
    margin-left: 1rem;
    }

    .nav-masthead .active {
    color: #fff;
    border-bottom-color: #fff;
    }

    @media (min-width: 48em) {
    .masthead-brand {
        float: left;
    }
    .nav-masthead {
        float: right;
    }
    }


    /*
    * Cover
    */
    .cover {
    padding: 0 1.5rem;
    }
    .cover .btn-lg {
    padding: .75rem 1.25rem;
    font-weight: 700;
    }
    .cover-heading {
        img {
            width: 100%;
            max-width: 400px;
            height: auto;
            margin-bottom: 60px;
        }
    }
    .lead {
        .btn {
            margin: 0 10px;
        }
    }


    /*
    * Footer
    */
    .mastfoot {
    color: rgba(255, 255, 255, .5);
    }
}