table {
    color: #fff;
    width: 100%;
    text-align: left;
    border-collapse:separate; 
    border-spacing: 0 2px;
    tr {
        display: table-row;
        background: rgba(0,0,0,0.2);
        margin-bottom: 2px;
        th, td {
            line-height: 25px;
        }
    }
}